const regexList = {
    fullName: /^[a-zéèâêîôûäëïöüçùà]{2,}((-|\s)[a-zéèâêîôûäëïöüçùà]{2,}){1,4}$/i,
    name: /^[a-zéèâêîôûäëïöüçùà]{2,}((-|\s)[a-zéèâêîôûäëïöüçùà]{2,}){0,4}$/i,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordWeak: /^(?=.*[A-Z]).{6,}/,
    passwordWithoutRequirements: /.{1,}/,
    containsCapital: /^(?=.*[A-Z]).+/,
    containsDigit: /^(?=.*\d).+/,
    containsSpecialCharacter: /(?=.*[!@#$£¥€¨^‰±•¿’”„´≥≤∞…÷≠—¡«¶‘“°%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).+/,
    isURL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
}

export default regexList;

