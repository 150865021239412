import { css } from 'glamor';
import theme from "../../theme";

/**************************/
/***** BUTTON STYLE *****/
/**************************/

var container = css({
    display: "inline-flex",
    justifyContent: "center",
    color: theme.colors.textColor
});




var style = {
    container,
}


export default style;