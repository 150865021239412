import { css } from 'glamor';

/**************************/
/***** WRAPPER STYLE *****/
/**************************/

var container = css({
    display:"flex",
    flexDirection: "column",
    alignItems:"center", 
    width: "100%",
    height: "100%",
});




var style = {
    container,
}


export default style;