import { css } from 'glamor';

/**************************/
/***** ICON STYLE *****/
/**************************/

var container = css({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center"
});




var style = {
    container,
}


export default style;